import React from 'react';

import "./App.scss"; 

import { Row, Col } from "antd";
//import { Form, Input, Button, Alert } from 'antd';

import { SuggestedPlaceCards } from "./components/SuggestedPlaceCards";
import suggestedPlaces from "./data/homepageItems"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTree } from '@fortawesome/free-solid-svg-icons'

//import jsonp from "jsonp";

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      status: null, // for mailchimp
      message: null
    };

  }


  render() {

    /*
    const handleEmailSubscribe = values => {
      // goes to Mailchimp subscription
      // reference: https://github.com/revolunet/react-mailchimp-subscribe/blob/master/src/index.js

      //console.log('Form received:', values);

      var actionUrl = "https://groveflow.us19.list-manage.com/subscribe/post-json?u=6c2b1565ae3cf7e7740c2f53b&id=2c51bcce60";
      actionUrl += "&b_6c2b1565ae3cf7e7740c2f53b_2c51bcce60="; // antibot protection from Mailchimp template
      actionUrl += "&c=" // to avoid cross-domain issues via mailchimp
      actionUrl += "&EMAIL=" + encodeURIComponent(values.email); // actual email value; should be capital "EMAIL" for mailchimp 

      //console.log("actionUrl: "+actionUrl);

      // "jsonp is a method for sending JSON data without worrying about cross-domain issues"

      jsonp(
        actionUrl,
        {
          param: "c"
        },
        (err, data) => {
          if (err) {
            console.log(err);
            this.setState({
              status: "error",
              message: err
            }); 

          } else if (data.result !== "success") {
            console.log(err);
            console.log(data);

            this.setState({
              status: "error",
              message: data.msg
            });
          } else {
            console.log(data);
            this.setState({
              status: "success",
              message: data.msg
            });
          }
        }
      );

    };
    */
  
    return (

        <div>
          <div style={{ marginTop: "0px" }}>

            <Row className="hero-row">
              <div className="company-container">

                <Row justify="center">
                  <div className="company-name">
                    GroveFlow 
                  </div> 

                  <div className="divider-custom divider-light">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon"> { }
                      <FontAwesomeIcon icon={faTree} />&nbsp;
                      <FontAwesomeIcon icon={faTree} />&nbsp;
                      <FontAwesomeIcon icon={faTree} />
                    </div>
                    <div className="divider-custom-line"></div>
                  </div>

                  <div className="company-tagline">
                    Dashboards for your mission-critical data
                  </div>
                </Row>
               
                <Col
                  sm={{ span: 20, offset: 2 }}
                  md={{ span: 20, offset: 2 }}
                  lg={{ span: 20, offset: 2 }}
                >
                  <div className="main-results">
                    <SuggestedPlaceCards
                      suggestedPlaces={suggestedPlaces}
                    />
                  </div> 
                </Col>

                {/*
                <Row justify="center" className="email-subscribe-header">
                  Subscribe to email notifications:
                </Row>
                <Row justify="center">
                  <Form onFinish={handleEmailSubscribe} layout="inline">
                    <Form.Item name="email" type="email" rules={[
                      {
                        required: true,
                        message: 'Please enter an email address',
                      },
                    ]}>
                      <Input className="email-subscribe-input" size="large" placeholder="Email Address" />
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="submit" className="primary-button email-subscribe-button">Send</Button>
                    </Form.Item>
                  </Form>

                </Row>
                <Row justify="center" className="email-subscribe-footer">
                 {this.state.status === "success" &&  <Alert showIcon type="success" message={this.state.message} />}
                 {this.state.status === "error" && <Alert showIcon type="error" message={this.state.message} />}
                </Row> */}
                
                <br></br>
              </div>
            </Row>


            <br></br>
          </div>
        </div>
    );
  }
}

export default App;
